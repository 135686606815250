




































import Vue from 'vue'
import {IEngineModule} from "@/types"
import * as vuex from 'vuex'
import ForbidDanmakuCard from "@/components/ForbidDanmakuCard.vue"
import FocusArea from "@/components/FocusArea.vue"

export default Vue.extend({
  name: "Settings",
  components: {FocusArea, ForbidDanmakuCard},
  metaInfo: {
    title: '设定'
  },
  data() {
    return {
      inputValid: false,
      baseUrl: this.$store.state.baseUrl,
      engineModuleInput: undefined as IEngineModule | undefined,
      btnLoading: false
    }
  },
  computed: {
    ...vuex.mapState(['engineModule'])
  },
  watch: {
    engineModule: {
      immediate: true,
      handler() {
        if (this.engineModule) {
          this.engineModuleInput = JSON.parse(JSON.stringify(this.engineModule)) as IEngineModule
        }
      }
    }
  },
  methods: {
    applySettings() {
      this.btnLoading = true
      this.$store.commit('setBaseUrl', this.baseUrl)
      let promises = [] as Promise<any>[]
      if (this.engineModule) {
        let types = ['anime', 'danmaku']

        interface IModulePayload {
          module: string,
          enable: boolean
        }

        let payload = [] as IModulePayload[]
        for (let type of types) {
          for (let [index, module] of this.engineModuleInput![type as keyof IEngineModule].entries()) {
            if (module.enable !== this.engineModule[type][index].enable) {
              payload.push({
                module: module.module,
                enable: module.enable
              })
            }
          }
        }
        promises.push(this.$axios.post('system/modules', payload))
      }

      Promise.all(promises).then(() => {
        return this.$swal.success('设定已更新')
      }).then(() => {
        window.location.reload()
      })
    },
    validateBaseUrl() {
      if (!this.baseUrl.trim().length) {
        this.inputValid = false
        return '地址不可为空'
      }
      if (!this.isValidHttpUrl(this.baseUrl)) {
        this.inputValid = false
        return 'URL不合法'
      }
      if (!this.baseUrl.endsWith('/')) {
        this.inputValid = false
        return '地址需要以「/」结尾'
      }
      this.inputValid = true
      return true
    },
    isValidHttpUrl(string: string) {
      let url
      try {
        url = new URL(string)
      } catch (_) {
        return false
      }
      return url.protocol === "http:" || url.protocol === "https:"
    }
  }
})
