


















import Vue from "vue"
import FocusArea from "@/components/FocusArea.vue"
import {IAnimeDetail, IVideoListOfAnimeDetail} from "@/types"
import AnimeItem from "@/components/AnimeItem.vue"
import {MetaInfo} from "vue-meta"
import PlayList from "@/components/PlayList.vue"

export default Vue.extend({
  name: "Anime",
  components: {PlayList, AnimeItem, FocusArea},
  props: ['token'],
  metaInfo(): MetaInfo {
    return {
      title: this.animeDetail?.title || '动画详情'
    }
  },
  data() {
    return {
      animeDetail: undefined as IAnimeDetail | undefined
    }
  },
  watch: {
    token: {
      immediate: true,
      handler() {
        this.getAnimeDetail()
      }
    }
  },
  methods: {
    navigateBack() {
      this.$router.go(-1)
    },
    getAnimeDetail() {
      this.animeDetail = undefined
      this.$axios.get("anime/" + this.token).then(res => {
        this.animeDetail = res.data
        this.animeDetail!.cover_url = this.animeDetail!.cover_url.replace('http:http', 'http')
      }).catch(err => {
        this.$router.go(-1)
      })
    }
  }
})

