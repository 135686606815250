






















import Vue from 'vue'

export default Vue.extend({
  name: 'App',
  metaInfo: {
    titleTemplate: '%s | Anime'
  },
  data() {
    return {}
  },
  mounted() {
    if (!this.$store.state.baseUrl.length) {
      this.navigateToSettings()
      return
    }
    this.$axios.get('system/modules').then(res => {
      this.$store.commit('setEngineModule', res.data)
    })
  },
  methods:{
    navigateToSettings(){
      this.$router.push({name: 'Settings'})
    }
  }
})
