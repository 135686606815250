


















import Vue from 'vue'
import SearchBar from "@/components/SearchBar.vue"
import * as vuex from 'vuex'

export default Vue.extend({
  name: 'Index',
  components: {SearchBar},
  metaInfo: {
    title: '主页'
  },
  computed: {
    searchText: {
      set(value: string) {
        this.$store.commit('setSearchText', value)
      },
      get() {
        return this.$store.state.searchText
      }
    }
  },
  data() {
    return {}
  },
  methods: {
    navigateToSearch() {
      this.$router.push({name: 'Search', params: {searchTextPassed: this.searchText}})
    }
  }
})
