









import Vue from "vue"
import {IVideoListOfAnimeDetail} from "@/types"

export default Vue.extend({
  name: "PlayList",
  props: ['name', 'videoList'],
  methods: {
    navigateToWatch(videoList: IVideoListOfAnimeDetail) {
      let videoExploded = this.$helper.getVideoExplodedFromUrl(videoList.info)
      this.$router.push({
        name: 'Watch',
        params: {token: videoExploded.token, playlist: videoExploded.playlist + '', episode: videoExploded.episode + ''}
      })
    },
  }
})

