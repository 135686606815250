

















import Vue from "vue"

export default Vue.extend({
  name: "AnimeItem",
  props: ['cover', 'title', 'category', 'description', 'url', 'engine', 'score', 'disableHover']
})
