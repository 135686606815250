




























import Vue from "vue"
import {IDanmakuEpisode} from "@/types"
import {BulletOption} from "@nplayer/danmaku/dist/src/ts/danmaku/bullet"

export default Vue.extend({
  name: "DanmakuInsertBtn",
  props: ['url', 'title'],
  data() {
    return {
      dialog: false,
      loading: true,
      danmakuEpisodeList: [] as IDanmakuEpisode[],
    }
  },
  methods: {
    openDialog() {
      this.dialog = true
      if (!this.danmakuEpisodeList.length) {
        this.getDanmakuEpisodeList()
      }
    },
    getDanmakuEpisodeList() {
      this.loading = true
      this.$axios.get(this.url).then(res => {
        this.danmakuEpisodeList = res.data
        this.loading = false
      })
    },
    insertDanmaku(danmakuEpisode: IDanmakuEpisode) {
      this.loading = true

      interface IPosMap {
        [key: number]: string
      }

      let posMap = {
        0: 'scroll',
        1: 'top',
        2: 'bottom'
      } as IPosMap
      this.$axios.get(danmakuEpisode.data).then(res => {
        let arr = res.data.data
        let bulletsArr = [] as BulletOption[]
        for (let single of arr) {
          let time = single[0]
          let type = posMap[single[1]] as "scroll" | "top" | "bottom"
          let color = '#' + single[2].toString(16)
          let text = single[4]
          bulletsArr.push({
            color,
            text,
            time,
            type
          })
        }
        bulletsArr.sort((a, b) => a.time - b.time)
        this.$emit('insertDanmaku', bulletsArr)
        this.loading = false
        this.dialog = false
      })
    }
  }
})

